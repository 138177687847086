import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "using-3rd-party-apis"
    }}>{`Using 3rd party APIs`}</h1>
    <p>{`The serverless cloud function fun stuff begins now. You're going to talk to a 3rd party API and send a text message.`}</p>
    <p>{`You can use the same approach to work with any 3rd party API.`}</p>
    <p>{`This sort of glue code is the bread and butter of cloud functions. An event happens in your system, a 3rd party calls your function, a user clicks something, and your function talks to another API to do work.`}</p>
    <h2 {...{
      "id": "call-an-api"
    }}>{`Call an API`}</h2>
    <p>{`Twilio lets you send and receive SMS messages and calls. They have a great node.js library that does the hard work for you.`}</p>
    <p>{`Most serious API providers have a library you can use. For others I like to install `}<inlineCode parentName="p">{`isomorphic-fetch`}</inlineCode>{` and use the `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` method.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Move into `}<inlineCode parentName="p">{`exercise-3`}</inlineCode>{` from the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises"
      }}>{`serverless-workshop-exercises GitHub repository`}</a>{`.`}</p>
    <p>{`Export a `}<inlineCode parentName="p">{`handler`}</inlineCode>{` function from `}<inlineCode parentName="p">{`src/send-sms.ts`}</inlineCode>{` and send a text to your phone number. Try getting the text from the body payload and the `}<inlineCode parentName="p">{`to:`}</inlineCode>{` phone number from query params.`}</p>
    <p>{`Use a POST request.`}</p>
    <p>{`Here's how you send text with Twilio:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const client = twilio(accountSid, accountToken)

const msg = await client.messages.create({
  body: "hello from lambda",
  to: "<phone number>",
  from: "+13162519852", // a valid twilio phone number
})
`}</code></pre>
    <p>{`You can use my accountSid `}<inlineCode parentName="p">{`AC1c1db52be590e54e507969ae5c0b800a`}</inlineCode>{`. I'll give you the secret token on chat.`}</p>
    <TestCloudFunction serviceName="serverless" urlPlaceholder="<domain>/send-sms" jsonPlaceholder="{}" mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/commit/aaa42e8db0123f2a19eea92079e5184361340182"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/commit/aaa42e8db0123f2a19eea92079e5184361340182`}</a></p>
    <h2 {...{
      "id": "use-env-vars"
    }}>{`Use .env vars`}</h2>
    <p>{`Hardcoding secret keys in your code is insecure. A step up in security is using a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Install the `}<inlineCode parentName="p">{`serverless-dotenv-plugin`}</inlineCode>{` and enable it in serverless.yml.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

plugins:
  - serverless-dotenv-plugin
`}</code></pre>
    <p>{`Create a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file with secret variables. I recommend prefixing with the service name. Learned that lesson the hard way 😇`}</p>
    <pre><code parentName="pre" {...{}}>{`// .env

TWILIO_ACCOUNT_SID=AC1c1db52be590e54e507969ae5c0b800a
TWILIO_ACCOUNT_TOKEN=...
`}</code></pre>
    <p>{`Access them in your code with `}<inlineCode parentName="p">{`process.env.X`}</inlineCode></p>
    <TestCloudFunction serviceName="serverless" urlPlaceholder="<domain>/send-sms" jsonPlaceholder="{}" mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/commit/391db0ae8100cbca6515472527a9c76a2b85c0b3"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/commit/391db0ae8100cbca6515472527a9c76a2b85c0b3`}</a></p>
    <h2 {...{
      "id": "use-aws-secrets-manager"
    }}>{`Use AWS secrets manager`}</h2>
    <p>{`This is the most secure. It encrypts values at rest and ensures engineers don't keep secrets on their machines. Makes it easy to rotate as well.`}</p>
    <p>{`Due to the secure nature of secrets, you have to configure this manually.`}</p>
    <h3 {...{
      "id": "exercise-2"
    }}>{`Exercise`}</h3>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "https://console.aws.amazon.com/secretsmanager/home"
      }}>{`AWS Secrets Manager`}</a>{` console. Create a new secret.`}</p>
    <p>{`Choose `}<inlineCode parentName="p">{`other type of secrets`}</inlineCode>{` and add values from your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file. I recommend keeping the same name.`}</p>
    <p>{`Install the `}<inlineCode parentName="p">{`aws-sdk`}</inlineCode>{` and `}<inlineCode parentName="p">{`@types/aws-sdk`}</inlineCode>{`, then access your secrets with code like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { SecretsManager } from "aws-sdk"

const ssm = new SecretsManager({
  region: "us-east-1", // make sure this matches your region
})

const secret = await ssm
  .getSecretValue({ SecretId: "<your secret name>" })
  .promise()

const { TWILIO_ACCOUNT_SID, ... } = JSON.parse(secret?.SecretString)
`}</code></pre>
    <p>{`I recommend creating a function.`}</p>
    <p><strong parentName="p">{`Add IAM permissions to secrets manager for your project`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`provider:
  # ...
  iamRoleStatements:
    - Effect: "Allow"
      Action:
        - "secretsmanager:GetSecretValue"
      Resource: "arn:aws:secretsmanager:\${self:provider.region}:*"
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`serverless-iam-roles-per-function`}</inlineCode>{` lets you add permissions to specific Lambda functions.`}</p>
    <TestCloudFunction serviceName="serverless" urlPlaceholder="<domain>/send-sms" jsonPlaceholder="{}" mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution-2"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/commit/f3a4e6d70c634d8fc0d2e7c648b5a5d2ae4c84d3"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/commit/f3a4e6d70c634d8fc0d2e7c648b5a5d2ae4c84d3`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      